import { COMPONENTS } from '@/constants';

export const STATUS_OK = 200;
export const INTERNAL_SERVER_ERROR = 500;
export const STEP_OFFSET = 1;
export const PERCENT_MULTIPLIER = 100;

// For getAvgTimeToQuoteString function
export const HIGHEST_ALLOWED_RANGE = 2;
export const BUSINESS_DAYS_IN_WEEK = 5;

// For Energy Information Administration ID Mapping
export const POSTAL_CODE_UTILITIES_ENDPOINT = '/local-data/postal-code-utilities/';
export const POSTAL_CODE_UTILITIES_SUCCESS = 200;
export const EIAIDS_WITH_STORAGE_SAVINGS = [
    {
        eiaid: 16609,
        storage_savings_percent: 122,
    },
    {
        eiaid: 17609,
        storage_savings_percent: 146,
    },
    {
        eiaid: 14328,
        storage_savings_percent: 81,
    },
];

export const SKIP_STEP_IF_KNOWN: { [key: string]: { choiceField?: string; skipFields: string[] } } = {
    [COMPONENTS.POSTAL_CODE]: { choiceField: 'postalCodeChoice', skipFields: ['postalCode'] },
    [COMPONENTS.UTILITY_PROVIDER]: { choiceField: 'utilityType', skipFields: ['utilityName'] },
    [COMPONENTS.PRODUCT_INTEREST]: { choiceField: 'productInterestScreenChoice', skipFields: ['productInterest'] },
    [COMPONENTS.SHOPPING_FOR]: { choiceField: 'shoppingFor', skipFields: ['shoppingFor'] },
    [COMPONENTS.PROPERTY_TYPE]: { choiceField: 'propertyType', skipFields: ['propertyType'] },
    [COMPONENTS.OWNS_PROPERTY]: { choiceField: 'ownsProperty', skipFields: ['ownsProperty'] },
    [COMPONENTS.OWNS_PROPERTY_COMMERCIAL]: {
        choiceField: 'commercialOwnershipType',
        skipFields: ['commercialOwnershipType'],
    },
    [COMPONENTS.TIME_TO_PURCHASE]: { skipFields: ['timeToPurchase'] },
    [COMPONENTS.RENT_CRITERIA]: { choiceField: 'rentCriteria', skipFields: ['rentCriteria'] },
    [COMPONENTS.SUSTAINABILITY]: { skipFields: ['sustainability'] },
    [COMPONENTS.ELECTRICITY_BILL]: { skipFields: ['monthlyElectricityBill'] },
    [COMPONENTS.STORAGE_INTEREST]: { choiceField: 'storageInterest', skipFields: ['storageInterest'] },
    [COMPONENTS.STORAGE_REASON]: { skipFields: ['storageReason'] },
    [COMPONENTS.ADDRESS]: {
        skipFields: ['streetAddress', 'postalCode', 'latitude', 'longitude', 'city', 'county', 'state', 'fullAddress'],
    },
    [COMPONENTS.ROOF_AGE]: { skipFields: ['roofAge'] },
    [COMPONENTS.REMOVE_TREES]: { skipFields: ['canRemoveTrees'] },
    [COMPONENTS.ORGANIZATION_NAME]: { skipFields: ['organizationName'] },
    [COMPONENTS.NAME]: { skipFields: ['firstName', 'lastName'] },
    [COMPONENTS.EMAIL]: { skipFields: ['email'] },
    [COMPONENTS.PHONE]: { skipFields: ['phone'] },
    [COMPONENTS.HEAT_PUMPS_INSTALLER_SEARCH]: { choiceField: 'nexStarAction', skipFields: ['nexStarAction'] },
    [COMPONENTS.ROOF_PIN]: { skipFields: ['roofPinConfirmed'] },
};

export const FLOWS = {
    DEFAULT: 'default',
    SOLAR_PV: 'solar-pv',
    SOLAR_RESIDENTIAL: 'solar_residential',
    SOLAR_COMMERCIAL: 'solar_commercial',
    CONTACT_INFO_RESIDENTIAL: 'contact_info_residential',
    CONTACT_INFO_COMMERCIAL: 'contact_info_commercial',
    HEATPUMP: 'heatpump',
    SOLAR_RESIDENTIAL_RENT: 'solar_residential_rent',
    EV_CHARGER: 'ev-charger',
    EV_REFINEMENT: 'ev_refinement',
    SERVICE_ERROR: 'service-error',
    TEST_FLOW: 'test_flow',
};
